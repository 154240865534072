<template>
  <div class="notes-container" :style="containerStyle">
    <menu-bar v-if="!!editor" :editor="editor" class="menu-bar" ref="menuBarEl" :style="menuBarStyle" />
    <editor-content v-if="!!editor" :editor="editor" class="editor-content" ref="editorContentEl" />
  </div>
</template>

<script>
// Copyright (C) dātma, inc™ - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential

import { computed, inject, onBeforeUnmount, onMounted, ref, watch, watchEffect } from 'vue'
import { useStore } from 'vuex'
import { useEditor, EditorContent } from '@tiptap/vue-3'
import StarterKit from '@tiptap/starter-kit'
import Collaboration from '@tiptap/extension-collaboration'
import CollaborationCursor from '@tiptap/extension-collaboration-cursor'
import TaskList from '@tiptap/extension-task-list'
import TaskItem from '@tiptap/extension-task-item'
import Typography from '@tiptap/extension-typography'
import Highlight from '@tiptap/extension-highlight'
import TextAlign from '@tiptap/extension-text-align'
import * as Y from 'yjs'

import { convertRemToPixels, heightInPixelsFromRows, themeColors } from '@/common/shared.js'
import styleVariables from '@/components/ui/BaseTool/BaseTool.vue?vue&type=style&index=0&lang=scss&module=1'
import getEnv from '@/utils/env'

import MenuBar from './MenuBar.vue'
import { EmitterProvider } from './y-emitter.js'

const CustomTaskItem = TaskItem.extend({
  content: 'paragraph',
})

const getRandomElement = (list) => list[Math.floor(Math.random() * list.length)]

const getRandomColor = () => getRandomElement([
  '#958DF1',
  '#F98181',
  '#FBBC88',
  '#FAF594',
  '#70CFF8',
  '#94FADB',
  '#B9F18D',
])

export default {
  components: {
    EditorContent,
    MenuBar,
  },
  props: {
    isDraggingToolId: {
      type: String,
      required: true,
    },
    isFullscreenToolId: {
      type: String,
      required: true,
    },
    payload: {
      type: Object,
      required: true,
    },
    sessionId: {
      type: String,
      required: true,
    },
    sessionOwnerId: {
      type: String,
      required: true,
    },
    toolWidthInColumns: {
      type: Number,
      required: true,
    },
    toolHeightInRows: {
      type: Number,
      required: true,
    },
    toolHeightInPixels: {
      type: Number,
      required: true,
    },
    toolId: {
      type: String,
      required: true,
    },
    windowHeight: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    const store = useStore()

    const currentUser = {
      name: store.getters.profile.displayName,
      color: getRandomColor(),
    }
    const users = ref([])

    const ydoc = new Y.Doc()
    const status = ref('connecting')
    const roomname = `v1/sessions/${props.sessionOwnerId}/${props.sessionId}/ydoc/${props.toolId}`
    const publishToKey = inject('publishToKey')
    // channel: v1/sessions/#/ (Read, Write, and Presence permissions)
    const sessionChannelKey = getEnv('VUE_APP_PUBSUB_SESSION_CHANNEL_KEY')
    const publishClient = publishToKey(sessionChannelKey)
    const send = (message) => publishClient({ channel: roomname, message })

    const provider = new EmitterProvider(send, ydoc)
    provider.on('status', event => status.value = event.status)
    const registerPubsubHandler = inject('registerPubsubHandler')
    const unregisterPubsubHandler = inject('unregisterPubsubHandler')

    const editor = useEditor({
      // content: props.payload.data.content,
      extensions: [
        StarterKit.configure({ history: false }),
        Highlight,
        TaskList,
        TextAlign.configure({
          alignments: ['left', 'center', 'right', 'justify'],
          defaultAlignment: 'left',
          types: ['heading', 'paragraph'],
        }),
        Typography,
        CustomTaskItem,
        CollaborationCursor.configure({
          provider,
          user: currentUser,
          onUpdate: (u) => users.value = u,
        }),
        Collaboration.configure({
          document: ydoc,
        }),
      ],
    })

    const onEditorUpdate = () => {
      const content = editor.value.getHTML()
      // This is a hack that corresponds to the updateEditorIfNeeded hack
      // below.  If the editor update attempts to wipe out all content,
      // don't let it save this to the backend, in the hopes that this prevents
      // data loss.
      if (content === '<p></p>') { return }
      // console.log(`onEditorUpdate: content: ${content.length} bytes: ${content}`)
      const payload = {
        ...props.payload,
        ...{ data: { content } },
      }
      const sessionOwnerId = props.sessionOwnerId
      const sessionId = props.sessionId
      const toolId = props.toolId
      const message = {
        payload,
        sessionOwnerId,
        sessionId,
        toolId,
        publish: true,
      }
      store.dispatch('updateToolPayload', message)
    }

    const updateEditorIfNeeded = (content) => {
      if (!editor.value || content === '<p></p>') { return }
      const currentContent = editor.value.getHTML()
      // console.log(`currentContent: ${currentContent.length}`)
      if (content === currentContent) { return }
      // This is a hack - allow any existing YDocs to fill in the content
      // before attempting to fill it in. When there is only a single
      // user in this session, the contents must be filled in from the
      // backend, but if there are other live sessions, YDoc fills in
      // the contents. TODO: Figure out how to consistently initialize
      // the content from the backend or from another YDoc without conflict
      // and ideally without using a setTimeout.
      if (currentContent === '<p></p>') {  // uninitialized document
        setTimeout(() => {
          const lazySyncedContent = editor.value.getHTML()
          // console.log(`lazySyncedContent: ${lazySyncedContent.length}`)
          if (lazySyncedContent === '<p></p>') {  // *still* uninitialized document
            console.log(`SETTING CONTENT NOW!!!  ${content.length} BYTES!!!`)
            try {
              editor.value.commands.setContent(content, false)
            } catch (err) {
              console.log(`editor initial sync error:`, err)
            }
            onEditorUpdate()
          }
        }, 1000)
      }
    }

    const storedContent = computed(() => props.payload.data.content)

    const containerStyle = computed(() => ({
      backgroundColor: themeColors[store.getters.currentThemeName].modalBackgroundColor,
      color: themeColors[store.getters.currentThemeName].modalTextColor,
    }))
    const menuBarStyle = computed(() => ({
      backgroundColor: themeColors[store.getters.currentThemeName].toolInputBackgroundColor,
      borderColor: themeColors[store.getters.currentThemeName].secondaryButtonBackgroundColor,
      color: themeColors[store.getters.currentThemeName].modalTextColor,
    }))

    const editorContentEl = ref(null)
    const isSessionReadOnly = computed(() => store.getters.isSessionReadOnly)
    const setContentEditable = () => {
      const contentEditable = !isSessionReadOnly.value
      const proseMirror = editorContentEl.value.rootEl.firstElementChild
      if (!proseMirror) { return }
      proseMirror.setAttribute('contenteditable', contentEditable)
      proseMirror.style.userSelect = 'none'
    }
    watch(editorContentEl, (el) => {
      if (!el) { return }
      setContentEditable()
    })

    onMounted(() => {
      if (editor.value) {
        editor.value.on('update', onEditorUpdate)
        registerPubsubHandler(roomname, provider.onmessage)
        const content = storedContent.value
        // console.log(`storedContent: ${content.length} bytes: ${content}`)
        updateEditorIfNeeded(content)
      }
    })

    onBeforeUnmount(() => {
      if (editor.value) {
        unregisterPubsubHandler(roomname)
        editor.value.destroy()
      }
    })

    const updateEditorHeight = (toolHeightInPx) => {
      const toolHeaderHeightInPx = convertRemToPixels(parseFloat(styleVariables.toolHeaderHeight))
      const toolTopAndBottomBorderWidthInPx = 2 * parseFloat(styleVariables.toolBorderWidth)
      if (!editor.value || !menuBarEl.value) { return }
      // Note that after 2022-06-01, we noticed that menuBarEl.value.$el was uninitialized and
      // its bounding box values were completely non-sensical, like this:
      // bottom: 606, height: 493, left: 1, right: 1, top: 113, width: 0, x: 1, y: 113
      // Therefore, as a workaround hack, we now get the height of the menu bar, and if it is > 90 pixels, we set it to 29.
      const menuBarHeightHack = menuBarEl.value.$el.getBoundingClientRect().height
      const menuBarHeight = menuBarHeightHack > 90 ? 29 : menuBarHeightHack
      editorHeight = toolHeightInPx - toolHeaderHeightInPx - menuBarHeight - toolTopAndBottomBorderWidthInPx
      const heightStr = `${editorHeight}px`
      editor.value.view.dom.style.height = heightStr
    }

    const menuBarEl = ref(null)
    let editorHeight = null
    watchEffect(() => {
      // The 0* is to force watchEffect to recalculate when the toolWidthInColumns changes.
      const toolHeightInPx = heightInPixelsFromRows(props.toolHeightInRows) + 0 * props.toolWidthInColumns
      updateEditorHeight(toolHeightInPx)
    })

    const isFullscreen = computed(() => props.isFullscreenToolId == props.toolId)
    watch(isFullscreen, () => {
      const toolHeightInPx = isFullscreen.value ? props.windowHeight : heightInPixelsFromRows(props.toolHeightInRows)
      updateEditorHeight(toolHeightInPx)
    })

    const isDragging = computed(() => props.isDraggingToolId == props.toolId)
    const draggingToolHeight = computed(() => {
      return (isDragging.value) ? props.toolHeightInPixels : heightInPixelsFromRows(props.toolHeightInRows)
    })
    watch(draggingToolHeight, () => {
      updateEditorHeight(draggingToolHeight.value)
    })

    return {
      containerStyle,
      editor,
      editorContentEl,
      menuBarEl,
      menuBarStyle,
    }
  },
}
</script>

<style lang="scss">
.notes-container {
  position: static;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
}

.menu-bar {
  height: auto;
  flex: 0 1 auto;
  padding: 0.4rem;
  border-bottom: 1px solid black;
}

.editor-content {
  min-height: 50%;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
}

.editor-content>div {
  height: 100%;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
}

.collaboration-cursor__caret {
  position: relative;
  margin-left: -1px;
  margin-right: -1px;
  border-left: 1px solid #0d0d0d;
  border-right: 1px solid #0d0d0d;
  word-break: normal;
  pointer-events: none;
}

/* Render the username above the caret */
.collaboration-cursor__label {
  position: absolute;
  top: -1.4em;
  left: -1px;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: #0d0d0d;
  padding: 0.1rem 0.3rem;
  border-radius: 3px 3px 3px 0;
  white-space: nowrap;
}

.ProseMirror {
  position: static;
  flex-grow: 1;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  padding: 0.5rem;
  text-align: left;

  >* {
    margin-top: 0.5rem;
  }

  ul,
  ol {
    padding: 0 1rem;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: 1.1;
  }

  code {
    font-family: monospace;
    font-size: 1.2rem;
  }

  &:focus {
    outline: none;
  }
}
</style>
